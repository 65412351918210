// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'bootstrap'
import 'jquery'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function() {
  const $form = $('#edit_profile_form');
  $form.find("input[type=text], select, input[type=date], input[type=number]").on('focusout', function() {
    save_field($(this));
  });
  $form.find("input[type=checkbox], input[type=radio]").on('change click', function() {
    save_field($(this));
  });
  $form.find("textarea").on("input propertychange", function() {
    debounced_save_field($(this));
  });
  $form.find("input[type=file]").on("change", function() {
    $form.trigger("submit");
  });
  $form.find("#add-portrait-button").on("click", function() {
    $form.find("#profile_portraits").trigger("click");
  })
  $form.find("#add-photo-button").on("click", function() {
    $form.find("#profile_photos").trigger("click");
  })

  function save_field($field) {
    $.ajax({
      url: $form.data('url'),
      method: $form.attr('method'),
      dataType: 'json',
      data: {
        field: $field.attr('name'),
        value: $field.val()
      },
      beforeSend: function() {
        console.log('before send');
      },
      error: function(err) {
        console.log(err);
      },
    }).done(function(data) {
      console.log(data);
    });
  }

  let timer;
  function debounced_save_field($field) {
    clearTimeout(timer);
    timer = setTimeout(function() {
      save_field($field);
    }, 1500);
  }

  const $new_user_form = $("form#new_user").first();
  $new_user_form.find(".collapse").each(function () {
    const $parent = $(this);
    $parent.find("a.button.advance").first().on("click", function(e) {
      if ($parent.is("#step-1")) {
        const inerrancy = $parent.find("#conviction-inerrancy")[0].checked;
        const god = $parent.find("#conviction-god")[0].checked;
        const jesus = $parent.find("#conviction-jesus")[0].checked;
        const salvation = $parent.find("#conviction-salvation")[0].checked;
        const resurrection = $parent.find("#conviction-resurrection")[0].checked;
        if (!inerrancy || !god || !jesus || !salvation || !resurrection) {
          if (!inerrancy) {
            $parent.find("#conviction-inerrancy").addClass("is-invalid")
          } else {
            $parent.find("#conviction-inerrancy").removeClass("is-invalid")
          }
          if (!god) {
            $parent.find("#conviction-god").addClass("is-invalid")
          } else {
            $parent.find("#conviction-god").removeClass("is-invalid")
          }
          if (!jesus) {
            $parent.find("#conviction-jesus").addClass("is-invalid")
          } else {
            $parent.find("#conviction-jesus").removeClass("is-invalid")
          }
          if (!salvation) {
            $parent.find("#conviction-salvation").addClass("is-invalid")
          } else {
            $parent.find("#conviction-salvation").removeClass("is-invalid")
          }
          if (!resurrection) {
            $parent.find("#conviction-resurrection").addClass("is-invalid")
          } else {
            $parent.find("#conviction-resurrection").removeClass("is-invalid")
          }
          console.log("error")
        } else {
          $parent.find("#conviction-inerrancy").removeClass("is-invalid")
          $parent.find("#conviction-god").removeClass("is-invalid")
          $parent.find("#conviction-jesus").removeClass("is-invalid")
          $parent.find("#conviction-salvation").removeClass("is-invalid")
          $parent.find("#conviction-resurrection").removeClass("is-invalid")
          $(".progress-heading").text("Step 2 of 5");
          $(".progress-bar").css("width", "40%");
          $parent.siblings("#step-2").collapse("show");
        }
      }
      if ($parent.is("#step-2")) {
        const fruitfulness = $parent.find("#conviction-fruitfulness")[0].checked;
        const husband_headship = $parent.find("#conviction-husband-headship")[0].checked;
        const wife_submission = $parent.find("#conviction-wife-submission")[0].checked;
        if (!fruitfulness || !husband_headship || !wife_submission) {
          if (!fruitfulness) {
            $parent.find("#conviction-fruitfulness").addClass("is-invalid")
          } else {
            $parent.find("#conviction-fruitfulness").removeClass("is-invalid")
          }
          if (!husband_headship) {
            $parent.find("#conviction-husband-headship").addClass("is-invalid")
          } else {
            $parent.find("#conviction-husband-headship").removeClass("is-invalid")
          }
          if (!wife_submission) {
            $parent.find("#conviction-wife-submission").addClass("is-invalid")
          } else {
            $parent.find("#conviction-wife-submission").removeClass("is-invalid")
          }
        } else {
          $parent.find("#conviction-fruitfulness").removeClass("is-invalid")
          $parent.find("#conviction-husband-headship").removeClass("is-invalid")
          $parent.find("#conviction-wife-submission").removeClass("is-invalid")
          $(".progress-heading").text("Step 3 of 5");
          $(".progress-bar").css("width", "60%");
          $parent.siblings("#step-3").collapse("show");
        }
      }
      if ($parent.is("#step-3")) {
        const dating_to_marry = $parent.find("#conviction-dating-to-marry")[0].checked;
        const holiness = $parent.find("#conviction-holiness")[0].checked;
        const track_record = $parent.find("#conviction-track-record")[0].checked;
        const endorsement_church = $parent.find("input[type=radio][name=endorsement_type][value=Church]")[0].checked;
        const endorsement_father = $parent.find("input[type=radio][name=endorsement_type][value=Father]")[0].checked;
        const endorsement_alt = $parent.find("input[type=radio][name=endorsement_type][value=Alternative]")[0].checked;
        const church_website = $parent.find("#church-website")[0];
        const alt_desc = $parent.find("#alternate-endorsement")[0];
        if (
          !dating_to_marry ||
          !holiness ||
          !track_record ||
          (!endorsement_church && !endorsement_father && !endorsement_alt) ||
          (endorsement_church && !church_website.value.length > 0) ||
          (endorsement_father && !alt_desc.value.length > 0) ||
          (endorsement_alt && !alt_desc.value.length > 0)
        ) {
          if (!dating_to_marry) {
            $parent.find("#conviction-dating-to-marry").addClass("is-invalid")
          } else {
            $parent.find("#conviction-dating-to-marry").removeClass("is-invalid")
          }
          if (!holiness) {
            $parent.find("#conviction-holiness").addClass("is-invalid")
          } else {
            $parent.find("#conviction-holiness").removeClass("is-invalid")
          }
          if (!track_record) {
            $parent.find("#conviction-track-record").addClass("is-invalid")
          } else {
            $parent.find("#conviction-track-record").removeClass("is-invalid")
          }
          if (!endorsement_church && !endorsement_father && !endorsement_alt) {
            $parent.find("input[type=radio][name=endorsement_type]").addClass("is-invalid")
          } else {
            $parent.find("input[type=radio][name=endorsement_type]").removeClass("is-invalid")
          }
          if (endorsement_church && !church_website.value.length > 0) {
            $parent.find("input#church-website").addClass("is-invalid")
          } else {
            $parent.find("input#church-website").removeClass("is-invalid")
          }
          if ((endorsement_father && !alt_desc.value.length > 0) || (endorsement_alt && !alt_desc.value.length > 0)) {
            $parent.find("textarea#alternate-endorsement").addClass("is-invalid")
          } else {
            $parent.find("textarea#alternate-endorsement").removeClass("is-invalid")
          }
        } else {
          $parent.find("#conviction-dating-to-marry").removeClass("is-invalid")
          $parent.find("#conviction-holiness").removeClass("is-invalid")
          $parent.find("#conviction-track-record").removeClass("is-invalid")
          $parent.find("input[type=radio][name=endorsement_type]").removeClass("is-invalid")
          if ($parent.find("input[type=radio][name=endorsement_type]").parent(".form-check").children().last().is("div.invalid-feedback")) {
            $parent.find("input[type=radio][name=endorsement_type]").parent(".form-check").remove(".invalid-feedback")
          }
          $parent.find("input#church-website").removeClass("is-invalid")
          $parent.find("textarea#alternate-endorsement").removeClass("is-invalid")
          $(".progress-heading").text("Step 4 of 5");
          $(".progress-bar").css("width", "80%");
          $parent.siblings("#step-4").collapse("show");
        }
      }
      if ($parent.is("#step-4")) {
        const shepherd_first = $parent.find("#shepherd-first")[0];
        const shepherd_last = $parent.find("#shepherd-last")[0];
        const shepherd_email = $parent.find("#shepherd-email")[0];
        if (shepherd_first.value.length < 2 || shepherd_last.value.length < 2 || shepherd_email.value.length < 5) {
          if (shepherd_first.value.length < 2) {
            $parent.find("#shepherd-first").addClass("is-invalid")
          } else {
            $parent.find("#shepherd-first").removeClass("is-invalid")
          }
          if (shepherd_last.value.length < 2) {
            $parent.find("#shepherd-last").addClass("is-invalid")
          } else {
            $parent.find("#shepherd-last").removeClass("is-invalid")
          }
          if (shepherd_email.value.length < 5) {
            $parent.find("#shepherd-email").addClass("is-invalid")
          } else {
            $parent.find("#shepherd-email").removeClass("is-invalid")

          }
        } else {
          $parent.find("#shepherd-first").removeClass("is-invalid")
          $parent.find("#shepherd-last").removeClass("is-invalid")
          $parent.find("#shepherd-email").removeClass("is-invalid")
          $(".progress-heading").text("Step 5 of 5");
          $(".progress-bar").css("width", "100%");
          $parent.siblings("#step-5").collapse("show");
        }
      }
      if ($parent.is("#step-5")) {
        const first_name = $new_user_form.find("#user_first_name")[0].value;
        const last_name = $new_user_form.find("#user_last_name")[0].value;
        const email = $new_user_form.find("#user_email")[0].value;
        const phone = $new_user_form.find("#user_phone")[0].value;
        const password = $new_user_form.find("#user_password")[0].value;
        const password_confirmation = $new_user_form.find("#user_password_confirmation")[0].value;
        const dob = $new_user_form.find("input[name=dob]")[0].value;
        const gender = $new_user_form.find("input[name=gender]:checked").val();
        const marital_status = $new_user_form.find("input[name=marital_status]:checked").val();
        const divorced = $new_user_form.find("input[name=marital_status][value=Divorced]")[0].checked;
        const divorce = $new_user_form.find("textarea[name=divorce]")[0].value;
        const divorce_times = $new_user_form.find("input[name=divorce_times]")[0].value;
        const divorce_duration = $new_user_form.find("input[name=divorce_duration]")[0].value;
        const not_sex_offender = $new_user_form.find("#not-sex-offender")[0].checked;
        const no_cdd = $new_user_form.find("#no-cdd")[0].checked;
        const no_porn = $new_user_form.find("#no-porn")[0].checked;
        const good_faith = $new_user_form.find("#good-faith")[0].checked;

        if (
          first_name.length <= 2 ||
          last_name.length <= 2 ||
          email.length <= 5 ||
          phone.length < 10 ||
          password.length <= 6 ||
          password_confirmation.length <= 6 ||
          dob.length < 5 ||
          !gender || !not_sex_offender || !no_cdd || !no_porn || !good_faith || !marital_status ||
          (divorced && (divorce.length > 10 || divorce_times.length > 1 || divorce_duration.length > 1))
        ) {
          if (first_name.length <= 2) {
            $new_user_form.find("#user_first_name").addClass("is-invalid")
          } else {
            $new_user_form.find("#user_first_name").removeClass("is-invalid")
          }
          if (last_name.length <= 2) {
            $new_user_form.find("#user_last_name").addClass("is-invalid")
          } else {
            $new_user_form.find("#user_last_name").removeClass("is-invalid")
          }
          if (email.length <= 5) {
            $new_user_form.find("#user_email").addClass("is-invalid")
          } else {
            $new_user_form.find("#user_email").removeClass("is-invalid")
          }
          if (phone.length < 10) {
            $new_user_form.find("#user_phone").addClass("is-invalid")
          } else {
            $new_user_form.find("#user_phone").removeClass("is-invalid")
          }
          if (password.length <= 6) {
            $new_user_form.find("#user_password").addClass("is-invalid")
          } else {
            $new_user_form.find("#user_password").removeClass("is-invalid")
          }
          if (password_confirmation.length <= 6) {
            $new_user_form.find("#user_password_confirmation").addClass("is-invalid")
          } else {
            $new_user_form.find("#user_password_confirmation").removeClass("is-invalid")
          }
          if (dob.length < 5) {
            $new_user_form.find("input[name=dob]").addClass("is-invalid")
          } else {
            $new_user_form.find("input[name=dob]").removeClass("is-invalid")
          }
          if (!gender) {
            $parent.find("input[name=gender]").addClass("is-invalid")
            if (!$parent.find("#gender_error").children().last().is("div.invalid-feedback")) {
              $parent.find("#gender_error").append("<div class='invalid-feedback'>You must select a value to complete your application.</div>")
            }
          } else {
            $parent.find("input[name=gender]").removeClass("is-invalid")
            if ($parent.find("#gender_error").children().last().is("div.invalid-feedback")) {
              $parent.find("#gender_error").children().remove(".invalid-feedback")
            }
          }
          if (divorced) {
            if (divorce.length < 10) {
              $parent.find("#divorce").addClass("is-invalid")
              if (!$parent.find("#divorce").parent(".form-check").children().last().is("div.invalid-feedback")) {
                $parent.find("#divorce").parent(".form-check").append("<div class='invalid-feedback'>An explanation of your divorce is required to continue your application.</div>")
              }
            } else {
              $parent.find("#divorce").removeClass("is-invalid")
              if ($parent.find("#divorce").parent(".form-check").children().last().is("div.invalid-feedback")) {
                $parent.find("#divorce").parent(".form-check").remove(".invalid-feedback")
              }
            }
            if (divorce_times.length < 1) {
              $parent.find("#divorce_times").addClass("is-invalid")
              if (!$parent.find("#divorce_times").parent(".form-check").children().last().is("div.invalid-feedback")) {
                $parent.find("#divorce_times").parent(".form-check").append("<div class='invalid-feedback'>Disclosing the number of divorces you have had is required to continue your application.</div>")
              }
            } else {
              $parent.find("#divorce_times").removeClass("is-invalid")
              if ($parent.find("#divorce_times").parent(".form-check").children().last().is("div.invalid-feedback")) {
                $parent.find("#divorce_times").parent(".form-check").remove(".invalid-feedback")
              }
            }
            if (divorce_duration.length < 1) {
              $parent.find("#divorce_duration").addClass("is-invalid")
              if (!$parent.find("#divorce_duration").parent(".form-check").children().last().is("div.invalid-feedback")) {
                $parent.find("#divorce_duration").parent(".form-check").append("<div class='invalid-feedback'>A timeframe since your most recent divorce is required to continue your application.</div>")
              }
            } else {
              $parent.find("#divorce_duration").removeClass("is-invalid")
              if ($parent.find("#divorce_duration").parent(".form-check").children().last().is("div.invalid-feedback")) {
                $parent.find("#divorce_duration").parent(".form-check").remove(".invalid-feedback")
              }
            }
          }
          if (!marital_status) {
            $parent.find("input[name=marital_status]").addClass("is-invalid")
            if (!$parent.find("#marital_status_error").children().last().is("div.invalid-feedback")) {
              $parent.find("#marital_status_error").append("<div class='invalid-feedback'>You must select a value to complete your application.</div>")
            }
          } else {
            $parent.find("input[name=marital_status]").removeClass("is-invalid")
            if ($parent.find("#marital_status_error").children().last().is("div.invalid-feedback")) {
              $parent.find("#marital_status_error").children().remove(".invalid-feedback")
            }
          }
          if (!not_sex_offender) {
            $parent.find("#not-sex-offender").addClass("is-invalid")
          } else {
            $parent.find("#not-sex-offender").removeClass("is-invalid")
          }
          if (!no_cdd) {
            $parent.find("#no-cdd").addClass("is-invalid")
          } else {
            $parent.find("#no-cdd").removeClass("is-invalid")
          }
          if (!no_porn) {
            $parent.find("#no-porn").addClass("is-invalid")
          } else {
            $parent.find("#no-porn").removeClass("is-invalid")
          }
          if (!good_faith) {
            $parent.find("#good-faith").addClass("is-invalid")
          } else {
            $parent.find("#good-faith").removeClass("is-invalid")
          }
        } else {
          $new_user_form.find("#user_first_name").removeClass("is-invalid")
          $new_user_form.find("#user_last_name").removeClass("is-invalid")
          $new_user_form.find("#user_email").removeClass("is-invalid")
          $new_user_form.find("#user_phone").removeClass("is-invalid")
          $new_user_form.find("#user_password").removeClass("is-invalid")
          $new_user_form.find("#user_password_confirmation").removeClass("is-invalid")
          $new_user_form.find("input[name=dob]").removeClass("is-invalid")
          $parent.find("input[name=gender]").removeClass("is-invalid")
          if ($parent.find("#gender_error").children().last().is("div.invalid-feedback")) {
            $parent.find("#gender_error").children().remove(".invalid-feedback")
          }
          $parent.find("#divorce").removeClass("is-invalid")
          if ($parent.find("#divorce").parent(".form-check").children().last().is("div.invalid-feedback")) {
            $parent.find("#divorce").parent(".form-check").remove(".invalid-feedback")
          }
          $parent.find("#divorce_times").removeClass("is-invalid")
          if ($parent.find("#divorce_times").parent(".form-check").children().last().is("div.invalid-feedback")) {
            $parent.find("#divorce_times").parent(".form-check").remove(".invalid-feedback")
          }
          $parent.find("#divorce_duration").removeClass("is-invalid")
          if ($parent.find("#divorce_duration").parent(".form-check").children().last().is("div.invalid-feedback")) {
            $parent.find("#divorce_duration").parent(".form-check").remove(".invalid-feedback")
          }
          $parent.find("input[name=marital_status]").removeClass("is-invalid")
          if ($parent.find("#marital_status_error").children().last().is("div.invalid-feedback")) {
            $parent.find("#marital_status_error").children().remove(".invalid-feedback")
          }
          $parent.find("#not-sex-offender").removeClass("is-invalid")
          $parent.find("#not-cdd").removeClass("is-invalid")
          $parent.find("#no-porn").removeClass("is-invalid")
          $parent.find("#good_faith").removeClass("is-invalid")
        }
      }
    });

    $parent.find("a.button.reverse").first().on("click", function(e) {
      if ($parent.is("#step-2")) {
        $(".progress-heading").text("Step 1 of 5");
        $(".progress-bar").css("width", "20%");
        $parent.siblings("#step-1").collapse("show");
      }
      if ($parent.is("#step-3")) {
        $(".progress-heading").text("Step 2 of 5");
        $(".progress-bar").css("width", "40%");
        $parent.siblings("#step-2").collapse("show");
      }
      if ($parent.is("#step-4")) {
        $(".progress-heading").text("Step 3 of 5");
        $(".progress-bar").css("width", "60%");
        $parent.siblings("#step-3").collapse("show");
      }
      if ($parent.is("#step-5")) {
        $(".progress-heading").text("Step 4 of 5");
        $(".progress-bar").css("width", "80%");
        $parent.siblings("#step-4").collapse("show");
      }
    });
  })

  $new_user_form.find("#user_first_name, #user_last_name, #user_email, #user_phone, #user_password, #user_password_confirmation, input[name=dob], input[name=gender], input[name=marital_status], textarea[name=divorce], input[name=divorce_duration], #not-sex-offender, #no-cdd, #no-porn, #good-faith").on("change", function () {
    // Check that all required fields are filled out before activating the submit button
    const first_name = $new_user_form.find("#user_first_name")[0].value;
    const last_name = $new_user_form.find("#user_last_name")[0].value;
    const email = $new_user_form.find("#user_email")[0].value;
    const phone = $new_user_form.find("#user_phone")[0].value;
    const password = $new_user_form.find("#user_password")[0].value;
    const password_confirmation = $new_user_form.find("#user_password_confirmation")[0].value;
    const dob = $new_user_form.find("input[name=dob]")[0].value;
    const gender = $new_user_form.find("input[name=gender]:checked").val();
    const marital_status = $new_user_form.find("input[name=marital_status]:checked").val()
    const divorced = $new_user_form.find("input[name=marital_status][value=Divorced]")[0].checked;
    const divorce = $new_user_form.find("textarea[name=divorce]")[0].value;
    const divorce_times = $new_user_form.find("input[name=divorce_times]")[0].value;
    const divorce_duration = $new_user_form.find("input[name=divorce_duration]")[0].value;
    const not_sex_offender = $new_user_form.find("#not-sex-offender")[0].checked;
    const no_cdd = $new_user_form.find("#no-cdd")[0].checked;
    const no_porn = $new_user_form.find("#no-porn")[0].checked;
    const good_faith = $new_user_form.find("#good-faith")[0].checked;
    if (
      first_name.length >= 2 &&
      last_name.length >= 2 &&
      email.length >= 5 &&
      phone.length >= 10 &&
      password.length >= 6 &&
      password_confirmation.length >= 6 &&
      dob.length > 0 &&
      marital_status &&
      (!divorced || (divorce && divorce_times && divorce_duration)) &&
      gender && not_sex_offender && no_cdd && no_porn && good_faith
    ) {
      $new_user_form.find("#advance-final").addClass("hide").removeClass("show")
      $new_user_form.find("#submit-application").removeAttr("disabled")
      $new_user_form.find("#submit-container").removeClass("hide").addClass("show")
    } else {
      $new_user_form.find("#advance-final").removeClass("hide").addClass("show")
      $new_user_form.find("#submit-application").attr("disabled", "disabled")
      $new_user_form.find("#submit-container").addClass("hide").removeClass("show")
    }
  })

  $new_user_form.find("input[type=radio][name=endorsement_type]").on("change", function () {
    if (this.value == 'Church') {
      $("#church-endorsement").removeClass("hide").addClass("show")
      $("#alt-endorsement").addClass("hide").removeClass("show")
    } else {
      $("#alt-endorsement").removeClass("hide").addClass("show")
      $("#church-endorsement").addClass("hide").removeClass("show")
    }
  })

  $new_user_form.find("input[type=radio][name=marital_status]").on("change", function () {
    if (this.value == 'Divorced') {
      $("#divorce-info").removeClass("hide").addClass("show")
    } else {
      $("#divorce-info").addClass("hide").removeClass("show")
    }
  })

  const $endorse_user_form = $("form.edit_endorsement").first();
  $endorse_user_form.find(".collapse").each(function () {
    const $parent = $(this);
    $parent.find("a.button.advance").first().on("click", function(e) {
      if ($parent.is("#step-1")) {
        const inerrancy = $parent.find("#conviction-inerrancy")[0].checked;
        const god = $parent.find("#conviction-god")[0].checked;
        const jesus = $parent.find("#conviction-jesus")[0].checked;
        const salvation = $parent.find("#conviction-salvation")[0].checked;
        const resurrection = $parent.find("#conviction-resurrection")[0].checked;
        if (!inerrancy || !god || !jesus || !salvation || !resurrection) {
          if (!inerrancy) {
            $parent.find("#conviction-inerrancy").addClass("is-invalid")
          } else {
            $parent.find("#conviction-inerrancy").removeClass("is-invalid")
          }
          if (!god) {
            $parent.find("#conviction-god").addClass("is-invalid")
          } else {
            $parent.find("#conviction-god").removeClass("is-invalid")
          }
          if (!jesus) {
            $parent.find("#conviction-jesus").addClass("is-invalid")
          } else {
            $parent.find("#conviction-jesus").removeClass("is-invalid")
          }
          if (!salvation) {
            $parent.find("#conviction-salvation").addClass("is-invalid")
          } else {
            $parent.find("#conviction-salvation").removeClass("is-invalid")
          }
          if (!resurrection) {
            $parent.find("#conviction-resurrection").addClass("is-invalid")
          } else {
            $parent.find("#conviction-resurrection").removeClass("is-invalid")
          }
          console.log("error")
        } else {
          $parent.find("#conviction-inerrancy").removeClass("is-invalid")
          $parent.find("#conviction-god").removeClass("is-invalid")
          $parent.find("#conviction-jesus").removeClass("is-invalid")
          $parent.find("#conviction-salvation").removeClass("is-invalid")
          $parent.find("#conviction-resurrection").removeClass("is-invalid")
          $(".progress-heading").text("Step 2 of 4");
          $(".progress-bar").css("width", "50%");
          $parent.siblings("#step-2").collapse("show");
        }
      }
      if ($parent.is("#step-2")) {
        const fruitfulness = $parent.find("#conviction-fruitfulness")[0].checked;
        const husband_headship = $parent.find("#conviction-husband-headship")[0].checked;
        const wife_submission = $parent.find("#conviction-wife-submission")[0].checked;
        if (!fruitfulness || !husband_headship || !wife_submission) {
          if (!fruitfulness) {
            $parent.find("#conviction-fruitfulness").addClass("is-invalid")
          } else {
            $parent.find("#conviction-fruitfulness").removeClass("is-invalid")
          }
          if (!husband_headship) {
            $parent.find("#conviction-husband-headship").addClass("is-invalid")
          } else {
            $parent.find("#conviction-husband-headship").removeClass("is-invalid")
          }
          if (!wife_submission) {
            $parent.find("#conviction-wife-submission").addClass("is-invalid")
          } else {
            $parent.find("#conviction-wife-submission").removeClass("is-invalid")
          }
        } else {
          $parent.find("#conviction-fruitfulness").removeClass("is-invalid")
          $parent.find("#conviction-husband-headship").removeClass("is-invalid")
          $parent.find("#conviction-wife-submission").removeClass("is-invalid")
          $(".progress-heading").text("Step 3 of 4");
          $(".progress-bar").css("width", "75%");
          $parent.siblings("#step-3").collapse("show");
        }
      }
      if ($parent.is("#step-3")) {
        const discipleship = $parent.find("#confirm-discipleship")[0].checked;
        const piety = $parent.find("#confirm-piety")[0].checked;
        const alt_present = $parent.find("#confirm-discipleship-alt");
        const endorser_discipleship = $parent.find("#endorser-discipleship");
        const divorce_present = $parent.find("#confirm-divorce");
        const endorser_divorce = $parent.find("#endorser-divorce");
        if (
          !discipleship ||
          !piety ||
          (alt_present.length > 0 && !alt_present[0].checked) ||
          (endorser_discipleship.length > 0 && !endorser_discipleship[0].value.length > 0) ||
          (divorce_present.length > 0 && !divorce_present[0].checked) ||
          (endorser_divorce.length > 0 && !endorser_divorce[0].value.length > 0)
        ) {
          if (!discipleship) {
            $parent.find("#confirm-discipleship").addClass("is-invalid")
          } else {
            $parent.find("#confirm-discipleship").removeClass("is-invalid")
          }
          if (!piety) {
            $parent.find("#confirm-piety").addClass("is-invalid")
          } else {
            $parent.find("#confirm-piety").removeClass("is-invalid")
          }
          if (alt_present.length > 0 && !alt_present[0].checked) {
            $parent.find("#confirm-discipleship-alt").addClass("is-invalid")
          } else {
            $parent.find("#confirm-discipleship-alt").removeClass("is-invalid")
          }
          if (endorser_discipleship.length > 0 && !endorser_discipleship[0].value.length > 0) {
            $parent.find("#endorser-discipleship").addClass("is-invalid")
            if (!$parent.find("#endorser-discipleship").parent(".field").children().last().is("div.invalid-feedback")) {
              $parent.find("#endorser-discipleship").parent(".field").append("<div class='invalid-feedback'>A basic explanation must be provided to proceed.</div>")
            }
          } else {
            $parent.find("#endorser-discipleship").removeClass("is-invalid")
            if ($parent.find("#endorser-discipleship").parent(".field").children().last().is("div.invalid-feedback")) {
              $parent.find("#endorser-discipleship").parent(".field").remove(".invalid-feedback")
            }
          }
          if (divorce_present.length > 0 && !divorce_present[0].checked) {
            $parent.find("#confirm-divorce").addClass("is-invalid")
            if (!$parent.find("#confirm-divorce").parent(".form-check").children().last().is("div.invalid-feedback")) {
              $parent.find("#confirm-divorce").parent(".form-check").append("<div class='invalid-feedback'>If you cannot affirm this statement in good conscience you are not qualified to complete this endorsement.</div>")
            }
          } else {
            $parent.find("#confirm-divorce").removeClass("is-invalid")
            if ($parent.find("#confirm-divorce").parent(".form-check").children().last().is("div.invalid-feedback")) {
              $parent.find("#confirm-divorce").parent(".form-check").remove(".invalid-feedback")
            }
          }
          if (endorser_divorce.length > 0 && !endorser_divorce[0].value.length > 0) {
            $parent.find("#endorser-divorce").addClass("is-invalid")
            if (!$parent.find("#endorser-divorce").parent(".field").children().last().is("div.invalid-feedback")) {
              $parent.find("#endorser-divorce").parent(".field").append("<div class='invalid-feedback'>A basic explanation must be provided to proceed.</div>")
            }
          } else {
            $parent.find("#endorser-divorce").removeClass("is-invalid")
            if ($parent.find("#endorser-divorce").parent(".field").children().last().is("div.invalid-feedback")) {
              $parent.find("#endorser-divorce").parent(".field").remove(".invalid-feedback")
            }
          }
        } else {
          $parent.find("#confirm-discipleship").removeClass("is-invalid")
          $parent.find("#confirm-piety").removeClass("is-invalid")
          $parent.find("#confirm-discipleship-alt").removeClass("is-invalid")
          $parent.find("#endorser-discipleship").removeClass("is-invalid")
          if ($parent.find("#endorser-discipleship").parent(".field").children().last().is("div.invalid-feedback")) {
            $parent.find("#endorser-discipleship").parent(".field").remove(".invalid-feedback")
          }
          $parent.find("#confirm-divorce").removeClass("is-invalid")
          if ($parent.find("#confirm-divorce").parent(".form-check").children().last().is("div.invalid-feedback")) {
            $parent.find("#confirm-divorce").parent(".form-check").remove(".invalid-feedback")
          }
          $(".progress-heading").text("Step 4 of 4");
          $(".progress-bar").css("width", "100%");
          $parent.siblings("#step-4").collapse("show");
          $parent.find("#endorser-divorce").removeClass("is-invalid")
          if ($parent.find("#endorser-divorce").parent(".field").children().last().is("div.invalid-feedback")) {
            $parent.find("#endorser-divorce").parent(".field").remove(".invalid-feedback")
          }
        }
      }
    });
    $parent.find("a.button.reverse").first().on("click", function(e) {
      if ($parent.is("#step-2")) {
        $(".progress-heading").text("Step 1 of 4");
        $(".progress-bar").css("width", "25%");
        $parent.siblings("#step-1").collapse("show");
      }
      if ($parent.is("#step-3")) {
        $(".progress-heading").text("Step 2 of 4");
        $(".progress-bar").css("width", "50%");
        $parent.siblings("#step-2").collapse("show");
      }
      if ($parent.is("#step-4")) {
        $(".progress-heading").text("Step 3 of 4");
        $(".progress-bar").css("width", "75%");
        $parent.siblings("#step-3").collapse("show");
      }
    });
    $parent.find("input#signature").first().on("keyup", function(e) {
      const $gender_male = $parent.find("input[type=radio][name=gender][value=Male]")[0].checked;
      const $gender_female = $parent.find("input[type=radio][name=gender][value=Female]")[0].checked;
      if ($(this).val().length > 2 && ($gender_male || $gender_female)) {
        $parent.find(".btn#submit-endorsement").removeAttr("disabled")
      } else {
        $parent.find(".btn#submit-endorsement").attr("disabled", "disabled")
      }
    });
    $parent.find("input[type=radio][name=gender]").on("change", function () {
      if ($(this).val() && $parent.find("input#signature").val().length > 2) {
        $parent.find(".btn#submit-endorsement").removeAttr("disabled")
      } else {
        $parent.find(".btn#submit-endorsement").attr("disabled", "disabled")
      }
    });
  });
});